import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
    return (
        <main>
            <title>Hmmm.</title>
            <h1>Nous n'avons pas trouvé votre article</h1>
            <p>
                Désolé{" "}
                <span role="img" aria-label="Pensive emoji">
                    🤷‍♂️
                </span>{" "}
                Tu va le trouver.
                <br />
                {process.env.NODE_ENV === "development" ? (
                    <>
                        <br />
                        Un soucis ? 💆‍♂️
                        <br />
                    </>
                ) : null}
                <br />
                <Link to="/">Rentrer</Link>
            </p>
        </main>
    );
};

export default NotFoundPage;
